import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from '@ackee/fela';

import { routes } from 'config/routes';

import * as UI from '../../../../ui';
import { Image } from './Image';

import * as felaRules from './InternPosition.rules';

const { Text, Title, DynamicContainer, LocalizedLink, ContainerType, TextTypes, TitleTypes } = UI;

export const InternPosition = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <DynamicContainer
            eventId="trainee-box"
            extend={{ container: rules.extendContainer }}
            type={ContainerType.FLEX}
            as={ContainerType.SECTION}
        >
            <DynamicContainer extend={{ container: rules.extendImageContainer }}>
                <Image alt="careerPage.internPosition.title" />
            </DynamicContainer>
            <DynamicContainer type={ContainerType.FLEX} extend={{ container: rules.extendTextContainer }}>
                <DynamicContainer>
                    {/* @ts-expect-error */}
                    <Text extend={{ text: rules.extendContentText }} type={TextTypes.EXTRA_LARGE}>
                        <FormattedMessage id="careerPage.internPosition.content" />
                    </Text>
                </DynamicContainer>
                <DynamicContainer>
                    <LocalizedLink className={styles.link} to={routes.positions.intern}>
                        {/* @ts-expect-error */}
                        <Title type={TitleTypes.H3_LINK} extend={{ title: rules.extendTitle }}>
                            <FormattedMessage id="careerPage.internPosition.title" />
                        </Title>
                    </LocalizedLink>
                </DynamicContainer>
            </DynamicContainer>
        </DynamicContainer>
    );
};
