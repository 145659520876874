import React from 'react';
import { useFelaEnhanced } from '@ackee/fela';
import Spritesheet from 'react-responsive-spritesheet';

import * as felaRules from './SpriteAnimation.styles';

export const SpriteAnimation = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const [isVisible, setVisible] = React.useState(false);

    React.useEffect(() => {
        // Without timeout causes issues in built production version
        setTimeout(() => setVisible(true), 100);
    }, []);

    return (
        <div className={styles.sprite}>
            {isVisible && (
                <Spritesheet
                    className={styles.spriteElement}
                    image={`/images/career/spritesheet_flag.png`}
                    widthFrame={800}
                    heightFrame={528}
                    steps={67}
                    fps={25}
                    autoplay={true}
                    loop={true}
                    isResponsive
                    direction="forward"
                />
            )}
        </div>
    );
};
