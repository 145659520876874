import type { TRuleWithTheme } from '@ackee/fela';

const containerPadding = {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    marginBottom: '4rem',
};
export const extendContainer: TRuleWithTheme = ({ theme }) => ({
    gridRowGap: '5rem',
    position: 'relative',

    ...containerPadding,
    desktopFullHD: containerPadding,

    tabletLarge: {
        ...containerPadding,
        gridTemplateColumns: 'repeat(12, 1fr)',

        overflow: 'visible',

        ':before': {
            position: 'absolute',
            display: 'block',
            content: '""',
            width: '0.125rem',
            height: '100%',
            left: '0',
            right: '4.25rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: theme.colors.dividerGrey,
        },
    },
});
const notFoundContainerPadding = {
    paddingTop: '4rem',
    paddingBottom: '16rem',
};

export const extendNotFoundContainer: TRuleWithTheme = () => ({
    paddingTop: 0,
    paddingBottom: 0,
    tablet: notFoundContainerPadding,
    tabletLarge: notFoundContainerPadding,
});

const internContainerPadding = { paddingTop: '1rem', paddingBottom: '6.75rem' };

export const extendInternContainer: TRuleWithTheme = () => ({
    ...internContainerPadding,
    desktopFullHD: internContainerPadding,
    tabletLarge: internContainerPadding,
});

const titleContainerPadding = { paddingTop: '1rem', paddingBottom: '4.125rem' };

export const extendTitleContainer: TRuleWithTheme = () => ({
    ...titleContainerPadding,
    desktopFullHD: titleContainerPadding,
    tabletLarge: titleContainerPadding,
    display: 'flex',
});

export const title = () => ({
    fontSize: '2.5rem',
});
