import type { TRuleWithTheme } from '@ackee/fela';

export const extendMainContainer: TRuleWithTheme = () => ({
    tabletLarge: {
        paddingTop: 0,
    },
});

export const extendText: TRuleWithTheme = () => ({
    marginBottom: '5rem',
});
