import type { TRuleWithTheme } from '@ackee/fela';

export const container: TRuleWithTheme = () => ({
    position: 'relative',
    overflow: 'visible',
});

export const extendTitle: TRuleWithTheme = ({ theme }) => ({
    fontSize: '1.875rem',

    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',

    tablet: {
        fontSize: '3rem',
    },

    ':hover': {
        color: theme.colors.fuchsia,
    },
});

export const description: TRuleWithTheme = () => ({
    fontSize: '1.25rem',
    fontWeight: 500,
    display: 'block',
    marginTop: '1.25rem',
    zIndex: 2,
    position: 'relative',
});

export const descriptionLarge: TRuleWithTheme = () => ({
    fontSize: '3rem',
    fontWeight: 'bold',
    display: 'block',
    marginTop: '11.75rem',
});

export const link: TRuleWithTheme = () => ({
    textDecoration: 'none',
    display: 'inline-block',
    zIndex: 2,
    position: 'relative',

    ':hover ~ div': {
        opacity: 1,
    },
});
