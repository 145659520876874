import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';

import { useFelaEnhanced } from '@ackee/fela';

import * as felaRules from './TechButton.styles';

interface TechButtonProps {
    onClick: () => void;
    active: boolean;
    name: MessageDescriptor['id'];
}

export const TechButton = ({ onClick, active, name }: TechButtonProps) => {
    const { styles } = useFelaEnhanced(felaRules, { active });

    return (
        <button className={styles.button} type="button" onClick={onClick}>
            <FormattedMessage id={name} />
        </button>
    );
};
