import type { TRuleWithTheme } from '@ackee/fela';

import topLeftDivider from '../../../../../../static/images/career/positions/not-found-position/topLeftDivider.svg';
import bottomLeftDivider from '../../../../../../static/images/career/positions/not-found-position/bottomLeftDivider.svg';
import bottomRightDivider from '../../../../../../static/images/career/positions/not-found-position/bottomRightDivider.svg';

export const extendContainer: TRuleWithTheme = () => ({
    overflow: 'visible',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
    paddingTop: '3rem',
    paddingBottom: '3.375rem',
    gridAutoRows: '1fr',
    position: 'relative',
    backgroundImage: `url(${topLeftDivider}), url(${bottomLeftDivider}), url(${bottomRightDivider})`,
    backgroundPosition: 'top left, bottom left, bottom right',
    backgroundRepeat: 'no-repeat',

    tablet: {
        marginTop: '5rem',
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',
    },
});

export const extendContentContainer: TRuleWithTheme = () => ({
    position: 'relative',
    zIndex: 2,
});

export const extendQuestionMarkContainer: TRuleWithTheme = () => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'visible',
    width: '100%',
    height: '15rem',

    '& .gatsby-image-wrapper': {
        width: '17.75rem',
        right: '-2rem',
    },

    tablet: {
        height: '30rem',

        '& .gatsby-image-wrapper': {
            width: '37.5rem',
            top: '-8rem',
        },
    },
});

export const extendTitle: TRuleWithTheme = () => ({
    fontSize: '3rem',
    marginTop: 0,
    marginBottom: 0,
});

export const extendDescription: TRuleWithTheme = () => ({
    marginTop: '0.5rem',
    marginBottom: '2rem',
    maxWidth: '34rem',
});

export const link: TRuleWithTheme = ({ theme: { colors } }) => ({
    textDecoration: 'none',
    display: 'inline-block',
    color: colors.blue,
    fontSize: '1.25rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',

    borderBottom: `2px solid ${colors.blue}`,
    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',

    ':hover': {
        color: colors.fuchsia,
    },
});
