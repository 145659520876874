import type { TRuleWithTheme } from '@ackee/fela';

import background from '../../../../../../static/images/career/positions/inactiveLabelBackground.svg';

export const wrapper: TRuleWithTheme = () => ({
    marginLeft: '-7rem',
    paddingRight: '20rem',

    tablet: {
        marginLeft: '-4rem',
        paddingRight: '23rem',
    },
});

export const container: TRuleWithTheme = () => ({
    position: 'absolute',
    zIndex: 3,
    pointerEvents: 'none',
    width: '25.1875rem',
    height: '20.5625rem',
    transform: 'scale(0.7)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',

    tablet: {
        transform: 'scale(1)',
    },
});

export const text: TRuleWithTheme = () => ({
    fontWeight: 800,
    fontSize: '1.5rem',
    maxWidth: '14.375rem',
    textAlign: 'center',
    rotate: '6.32deg',
});
