import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img, { type FluidObject } from 'gatsby-image';

export const QuestionMark = () => {
    const { file } = useStaticQuery<Queries.Query>(graphql`
        query {
            file(relativePath: { eq: "career/positions/not-found-position/question-mark.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 430) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    if (!file?.childImageSharp?.fluid) return null;

    return (
        <Img
            style={{ height: 'auto', maxHeight: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
            fluid={file.childImageSharp.fluid as FluidObject}
        />
    );
};
