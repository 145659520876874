import React from 'react';
import { useIntl } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby';
import { useFelaEnhanced } from '@ackee/fela';
import type { FluidObject } from 'gatsby-image';

import type { MessageKey } from 'modules/intl';

import Img from '../../../../../../components/Img';

import * as felaRules from './Image.rules';

interface ImageProps {
    alt: MessageKey;
}

export const Image = ({ alt }: ImageProps) => {
    const { styles } = useFelaEnhanced(felaRules);
    const { formatMessage } = useIntl();
    const imageAlt = formatMessage({ id: alt });

    const { file } = useStaticQuery<Queries.Query>(graphql`
        query {
            file(relativePath: { eq: "career/positions/intern/interns.jpg" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    if (!file?.childImageSharp?.fluid) return null;

    return (
        <div className={styles.imageContainer}>
            <Img alt={imageAlt} className={styles.image} fluid={file.childImageSharp.fluid as FluidObject} />
        </div>
    );
};
