import type { TRuleWithTheme } from '@ackee/fela';

export const sprite: TRuleWithTheme = () => ({
    position: 'absolute',
    top: '17%',
    left: '48%',
});

export const spriteElement: TRuleWithTheme = () => ({
    opacity: 0.8,
    width: '50vw',
    maxWidth: 1212,
    height: '50vw',
    maxHeight: 800,
});
