import type { TRuleWithTheme } from '@ackee/fela';

import topRightDivider from '../../../../../../static/images/career/positions/internTopRightDivider.svg';
import bottomRightDivider from '../../../../../../static/images/career/positions/internBottomRightDivider.svg';

export const extendContainer: TRuleWithTheme = () => ({
    flexDirection: 'column',
    tablet: {
        flexDirection: 'row',
    },
    backgroundImage: `url(${topRightDivider}), url(${bottomRightDivider})`,
    backgroundPosition: 'top right, bottom right',
    backgroundRepeat: 'no-repeat',
});

export const extendImageContainer: TRuleWithTheme = () => ({
    width: '100%',
    tablet: {
        maxWidth: '35.5625rem',
    },
});

export const extendTextContainer: TRuleWithTheme = () => ({
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2.5rem 2rem 1rem 2rem',
    tablet: {
        padding: '2.5rem 4rem 1rem 4rem',
    },
});

export const extendContentText: TRuleWithTheme = ({ theme }) => ({
    ...theme.fonts.heroNewBold,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    tabletLarge: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
});

export const extendTitle: TRuleWithTheme = ({ theme }) => ({
    fontSize: '1.75rem',
    display: 'inline-block',

    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',

    tablet: {
        fontSize: '2rem',
    },

    ':hover': {
        color: theme.colors.fuchsia,
    },
});

export const link: TRuleWithTheme = () => ({
    textDecoration: 'none',

    ':hover ~ div': {
        opacity: 1,
    },
});
