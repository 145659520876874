import React from 'react';
import { useFelaEnhanced } from '@ackee/fela';

import * as felaRules from './HeroOverlay.rules';

export const HeroOverlay = () => {
    const { styles } = useFelaEnhanced(felaRules);

    return <div className={styles.container} />;
};
