import React, { forwardRef } from 'react';
import { useFelaEnhanced } from '@ackee/fela';

import { AnalyticsEvent } from 'utils/analytics';

import * as UI from '../../../ui';

import { content } from './content';
import * as felaRules from './GalleriesSection.rules';

const { DynamicContainer, ContainerType, TitleTypes, Title, Carousel, CarouselTypes, FormattedMessageWithLinks } = UI;

export const GalleriesSection = forwardRef<HTMLElement>((props, ref) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    return (
        <DynamicContainer
            as={ContainerType.SECTION}
            isFluid
            type={ContainerType.GRID}
            extend={{ container: rules.extendMainContainer }}
            ref={ref}
        >
            {/* @ts-expect-error */}
            <Title type={TitleTypes.H1_ITALIC_UPPERCASE} as={TitleTypes.H2} columns={12}>
                <FormattedMessageWithLinks id={content.sectionTitle} />
            </Title>

            {/* PORTRAIT */}
            <DynamicContainer extend={{ container: rules.extendPortraitContainer }} columns={4}>
                <p className={styles.paragraph}>
                    <FormattedMessageWithLinks id={content.paragraphs.customStack} />
                </p>
                <p className={styles.paragraphBottom}>
                    <FormattedMessageWithLinks id={content.paragraphs.weAreActive} />
                </p>
                <Carousel
                    isCareerPortrait
                    type={CarouselTypes.GALLERY}
                    galleryType={CarouselTypes.GALLERY_TYPES.CAREER_PORTRAIT}
                    content={content.images.portrait1}
                    fullscreen
                    lazyLoaded
                    containerEventId="carousel-picture-2"
                    analyticsEvent={AnalyticsEvent.CAROUSEL_PICTURE_2_CLICK}
                />
                <p className={styles.paragraphMiddle}>
                    <FormattedMessageWithLinks id={content.paragraphs.conferences} />
                </p>
                <Carousel
                    isCareerPortrait
                    type={CarouselTypes.GALLERY}
                    galleryType={CarouselTypes.GALLERY_TYPES.CAREER_PORTRAIT}
                    content={content.images.portrait2}
                    fullscreen
                    lazyLoaded
                    containerEventId="carousel-picture-4"
                    analyticsEvent={AnalyticsEvent.CAROUSEL_PICTURE_4_CLICK}
                />
            </DynamicContainer>

            {/* LANDSCAPE */}
            <DynamicContainer type={ContainerType.GRID} columns={8}>
                <DynamicContainer columns={12}>
                    <Carousel
                        isCareerPortrait
                        type={CarouselTypes.GALLERY}
                        galleryType={CarouselTypes.GALLERY_TYPES.CAREER_LANDSCAPE}
                        content={content.images.landscape1}
                        fullscreen
                        lazyLoaded
                        containerEventId="carousel-picture-1"
                        analyticsEvent={AnalyticsEvent.CAROUSEL_PICTURE_1_CLICK}
                    />
                </DynamicContainer>
                <DynamicContainer extend={{ container: rules.extendLandscapeTextContainer }} columns={6}>
                    <p className={styles.paragraph}>
                        <FormattedMessageWithLinks id={content.paragraphs.distanceAvailability} />
                    </p>
                </DynamicContainer>
                <DynamicContainer columns={6} extend={{ container: rules.extendLandscapeTextContainer }}>
                    <p className={styles.paragraph}>
                        <FormattedMessageWithLinks id={content.paragraphs.teamBuildings} />
                    </p>
                </DynamicContainer>
                <DynamicContainer columns={12}>
                    <Carousel
                        isCareerPortrait
                        type={CarouselTypes.GALLERY}
                        galleryType={CarouselTypes.GALLERY_TYPES.CAREER_LANDSCAPE}
                        content={content.images.landscape2}
                        fullscreen
                        lazyLoaded
                        containerEventId="carousel-picture-3"
                        analyticsEvent={AnalyticsEvent.CAROUSEL_PICTURE_3_CLICK}
                    />
                </DynamicContainer>
                <DynamicContainer extend={{ container: rules.extendLandscapeTextContainer }} columns={6}>
                    <p className={styles.paragraphBottom}>
                        <FormattedMessageWithLinks id={content.paragraphs.refreshment} />
                    </p>
                </DynamicContainer>
                <DynamicContainer extend={{ container: rules.extendLandscapeTextContainer }} columns={6}>
                    <p className={styles.paragraphBottom}>
                        <FormattedMessageWithLinks id={content.paragraphs.ownProjects} />
                    </p>
                </DynamicContainer>
                <DynamicContainer columns={6}>
                    <p className={styles.paragraph}>
                        <FormattedMessageWithLinks id={content.paragraphs.darlings} />
                    </p>
                </DynamicContainer>
            </DynamicContainer>
        </DynamicContainer>
    );
});
