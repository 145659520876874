import type { TRuleWithTheme } from '@ackee/fela';

import topLine from '../../../../../static/images/career/hero/overlay/topLine.svg';
import middleLine from '../../../../../static/images/career/hero/overlay/middleLine.svg';
import bottomLine from '../../../../../static/images/career/hero/overlay/bottomLine.svg';

export const container: TRuleWithTheme = () => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundImage: `url(${topLine}), url(${middleLine}), url(${bottomLine})`,
    backgroundPosition: 'top left 45%, top 20% right 3.375rem, bottom 10rem left 20%',
    backgroundRepeat: 'no-repeat',
    zIndex: 1,

    tabletLarge: {
        backgroundPosition: 'top 7.4375rem left 40%, top 40% right 5.375rem, bottom left 20%',
    },

    desktopFullHD: {
        marginTop: '5rem',
    },
});
