import React from 'react';

import { Layout } from '../modules/ui';
import { CareerPage } from '../modules/career-page';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const CareerPagePage = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo pageId={Constants.pages.CAREER} image="/images/img_ackee_share_career.png" />
            <Layout footerRef={footerRef}>
                <CareerPage ref={footerRef} />
            </Layout>
        </>
    );
};

export default CareerPagePage;
