import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from '@ackee/fela';

import * as felaRules from './TenYearsSection.rules';

import * as UI from '../../../ui';
import tenYearsPoster from '../../../../../static/images/video/10_years.png';

const tenYearsVideo = 'https://storage.googleapis.com/ackee-web-static/videos/career/10_years.mp4';

const { DynamicContainer, ContainerType, Text, TextTypes, Video } = UI;

export const TenYearsSection = () => {
    const { rules } = useFelaEnhanced(felaRules);
    return (
        <DynamicContainer extend={{ container: rules.extendMainContainer }} isFluid as={ContainerType.SECTION}>
            {/* @ts-expect-error */}
            <Text extend={{ text: rules.extendText }} type={TextTypes.SECTION_BLACK}>
                <FormattedMessage id="careerPage.tenYearsSection.sectionTitle" />
            </Text>
            {/* @ts-expect-error */}
            <Video eventId="video-10-years" large src={tenYearsVideo} poster={tenYearsPoster} />
        </DynamicContainer>
    );
};
