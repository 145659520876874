import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from '@ackee/fela';

import { routes } from 'config/routes';

import * as UI from '../../../../ui';
import { QuestionMark } from '../../QuestionMark';

import * as felaRules from './NotFoundPosition.rules';

const { DynamicContainer, Title, TitleTypes, LocalizedLink, Text, TextTypes, ContainerType } = UI;

export const NotFoundPosition = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <DynamicContainer
            extend={{ container: rules.extendContainer }}
            columns={12}
            type={ContainerType.GRID}
            as={ContainerType.SECTION}
        >
            <DynamicContainer extend={{ container: rules.extendContentContainer }} columns={6}>
                {/* @ts-expect-error */}
                <Title type={TitleTypes.H3} extend={{ title: rules.extendTitle }}>
                    <FormattedMessage id="careerPage.positions.careerNotFound.title" />
                </Title>
                {/* @ts-expect-error */}
                <Text type={TextTypes.LARGE} extend={{ text: rules.extendDescription }}>
                    <FormattedMessage id="careerPage.positions.careerNotFound.description" />
                </Text>
                <LocalizedLink className={styles.link} to={routes.positions.careerNotFound}>
                    <FormattedMessage id="careerPage.positions.careerNotFound.button" />
                </LocalizedLink>
            </DynamicContainer>
            <DynamicContainer extend={{ container: rules.extendQuestionMarkContainer }} columns={6}>
                <QuestionMark />
            </DynamicContainer>
        </DynamicContainer>
    );
};
