import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';
import { useFelaEnhanced } from '@ackee/fela';

import * as felaRules from './InactiveLabel.rules';

export interface InactiveLabelProps {
    messageId: MessageDescriptor['id'];
}

export const InactiveLabel = ({ messageId }: InactiveLabelProps) => {
    const { styles } = useFelaEnhanced(felaRules);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.text}>
                    <FormattedMessage id={messageId} />
                </div>
            </div>
        </div>
    );
};
