import type { MessageDescriptor } from 'react-intl';

const paragraphIds = [
    'customStack',
    'weAreActive',
    'distanceAvailability',
    'teamBuildings',
    'conferences',
    'refreshment',
    'ownProjects',
    'darlings',
] as const;

const paragraphs = Object.fromEntries(paragraphIds.map(item => [item, `careerPage.galleries.paragraph.${item}`])) as {
    [k in typeof paragraphIds[number]]: MessageDescriptor['id'];
};

// TODO add original image alts

const images = {
    landscape1: [
        {
            id: 1,
            src: 'career/galleries/landscape/1a_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
        {
            id: 2,
            src: 'career/galleries/landscape/1b_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
        {
            id: 3,
            src: 'career/galleries/landscape/1c_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
    ],
    landscape2: [
        {
            id: 1,
            src: 'career/galleries/landscape/1d_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
        {
            id: 2,
            src: 'career/galleries/landscape/1e_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
        {
            id: 3,
            src: 'career/galleries/landscape/1f_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
    ],
    portrait1: [
        {
            id: 1,
            src: 'career/galleries/portrait/2a_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
        {
            id: 2,
            src: 'career/galleries/portrait/2b_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
        {
            id: 3,
            src: 'career/galleries/portrait/2c_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
    ],

    portrait2: [
        {
            id: 1,
            src: 'career/galleries/portrait/2d_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
        {
            id: 2,
            src: 'career/galleries/portrait/2e_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
        {
            id: 3,
            src: 'career/galleries/portrait/2f_ackee_kariera.jpg',
            alt: 'careerPage.galleries.landscape.img.alt',
        },
    ],
} as const;

export const content = {
    sectionTitle: 'careerPage.galleries.title',
    paragraphs,
    images,
};
