export const imageContainer = () => ({
    position: 'absolute',

    opacity: 0,

    top: '-75%',
    left: '20%',
    zIndex: 0,

    height: 268,
    width: 268,

    '> div': {
        height: '100%',
    },
});
