import type { TRuleWithTheme } from '@ackee/fela';

import topRightDivider from '../../../../../static/images/career/technologies/topRightDivider.svg';

export const extendContainer: TRuleWithTheme = () => ({
    paddingBottom: 0,
    backgroundImage: `url(${topRightDivider})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'content-box',
});

export const buttonsContainer: TRuleWithTheme = () => ({
    marginBottom: '4.1875rem',
    marginTop: '5rem',
});
