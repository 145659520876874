import type { TRuleWithTheme } from '@ackee/fela';
import { combineRules } from 'fela';

import dividerImg from '../../../../../static/images/divider.svg';

export const extendMainContainer: TRuleWithTheme = () => ({
    paddingTop: '2rem',

    tablet: {
        paddingTop: '4rem',
        paddingBottom: 0,
    },

    tabletLarge: {
        paddingTop: '4rem',
        paddingBottom: 0,
    },
});

export const extendPortraitContainer: TRuleWithTheme = () => ({
    tabletMax: {
        marginBottom: '1.5rem',
    },
});

export const paragraph: TRuleWithTheme = ({ theme: { colors } }) => ({
    fontSize: '1.375rem',
    fontWeight: 500,
    lineHeight: '2rem',
    paddingLeft: '1.5rem',
    position: 'relative',
    marginTop: '3rem',

    tablet: {
        marginTop: 'unset',
        marginBottom: '9.25rem',
    },

    '&:before': {
        content: `url(${dividerImg})`,
        position: 'absolute',
        boxSizing: 'border-box',
        width: '0.3125rem',
        height: '5rem',
        borderRight: `0.125rem solid ${colors.dividerGrey}`,
        left: 0,
        top: '0.25rem',
        lineHeight: 0,
    },
});

export const paragraphBottom: TRuleWithTheme = combineRules(paragraph, () => ({
    marginBottom: '3rem',

    tablet: {
        marginBottom: '6.125rem',
    },
}));

export const paragraphMiddle: TRuleWithTheme = combineRules(paragraph, () => ({
    marginTop: '3rem',
    marginBottom: '3rem',

    tablet: {
        marginBottom: '3rem',
        marginTop: '6.25rem',
    },
}));

export const extendLandscapeTextContainer: TRuleWithTheme = () => ({
    tablet: {
        paddingTop: '4.625rem',
    },
});
