import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from '@ackee/fela';

import Image from './Image';

import * as UI from '../../../../ui';

import * as felaRules from './SinglePosition.rules';
import type { Column } from '../content';

const { DynamicContainer, Title, TitleTypes, LocalizedLink } = UI;

interface SinglePositionProps {
    content: Column;
}

export const SinglePosition = ({ content }: SinglePositionProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const { title, description, link, img } = content;

    return (
        <DynamicContainer extend={{ container: rules.container }} columns={6}>
            <LocalizedLink className={styles.link} to={link}>
                {/* @ts-expect-error */}
                <Title type={TitleTypes.H3_LINK} extend={{ title: rules.extendTitle }}>
                    <FormattedMessage id={title} />
                </Title>
            </LocalizedLink>
            <span className={styles.description}>
                <FormattedMessage id={description} />
            </span>
            {/* @ts-expect-error */}
            {img ? <Image src={img} alt={title} /> : null}
        </DynamicContainer>
    );
};

export default SinglePosition;
