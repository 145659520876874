import React from 'react';
import { useFelaEnhanced } from '@ackee/fela';

import { FormattedMessage } from 'react-intl';

import * as UI from '../../../ui';
import { content } from './content';
import { TechButton } from './TechButton';
import * as felaRules from './TechnologiesSection.styles';

const { DynamicContainer, ContainerType, Technologies, Text, TextTypes } = UI;

export type CategoryKey = keyof typeof content;

export const TechnologiesSection = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const [activeButton, setActiveButton] = React.useState<CategoryKey>('mobile');
    const [fade, setFade] = React.useState(false);

    const handleClick = (key: CategoryKey) => {
        setFade(true);

        setTimeout(() => {
            setActiveButton(key);
            setFade(false);
        }, 300);
    };

    return (
        <DynamicContainer extend={{ container: rules.extendContainer }} isFluid as={ContainerType.SECTION}>
            {/* @ts-expect-error */}
            <Text type={TextTypes.SECTION_BLACK}>
                <FormattedMessage id="careerPage.technologies.sectionTitle" />
            </Text>
            <div className={styles.buttonsContainer}>
                {React.useMemo(
                    () =>
                        Object.entries(content).map(([key, value]) => (
                            <TechButton
                                key={key}
                                name={value.name}
                                onClick={() => handleClick(key as CategoryKey)}
                                active={key === activeButton}
                            />
                        )),
                    [activeButton],
                )}
            </div>
            <Technologies isCareer fade={fade} content={content[activeButton]} />
        </DynamicContainer>
    );
};
