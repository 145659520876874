import React, { type ForwardedRef, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from '@ackee/fela';

import * as felaRules from './PositionsSection.rules';

import * as UI from '../../../ui';

import { activeColumns, inactiveColumns, isInternPositionActive, getTitle } from './content';

import { SinglePosition } from './SinglePosition';
import { InternPosition } from './InternPosition';
import { NotFoundPosition } from './NotFoundPosition';
import { InactiveLabel } from './InactiveLabel';

const { DynamicContainer, ContainerType, TitleTypes, Title } = UI;

export enum PositionsSectionType {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    ALL_INACTIVE = 'all_inactive',
}

interface PositionsSectionProps {
    type: PositionsSectionType;
}

export const PositionsSection = forwardRef(({ type }: PositionsSectionProps, ref: ForwardedRef<HTMLElement>) => {
    const { rules } = useFelaEnhanced(felaRules);
    const columns = type === PositionsSectionType.ACTIVE ? activeColumns : inactiveColumns;
    const title = getTitle(type);

    return (
        <section ref={ref}>
            <DynamicContainer isFluid as={ContainerType.SECTION} extend={{ container: rules.extendTitleContainer }}>
                {/* @ts-expect-error*/}
                <Title
                    type={TitleTypes.H1_ITALIC_UPPERCASE}
                    extend={{ title: rules.title }}
                    as={TitleTypes.H2}
                    columns={12}
                >
                    <FormattedMessage id={title} />
                </Title>
                {type !== PositionsSectionType.ACTIVE && <InactiveLabel messageId="careerPage.positions.empty" />}
            </DynamicContainer>
            {type === PositionsSectionType.ACTIVE && isInternPositionActive && (
                <DynamicContainer extend={{ container: rules.extendInternContainer }} isFluid>
                    <InternPosition />
                </DynamicContainer>
            )}
            <DynamicContainer
                extend={{ container: rules.extendContainer }}
                type={ContainerType.GRID}
                as={ContainerType.SECTION}
                isFluid
            >
                {columns.map(item => (
                    <SinglePosition key={item.id} content={item} />
                ))}
            </DynamicContainer>
            {(type !== PositionsSectionType.ACTIVE || inactiveColumns.length === 0) && (
                <DynamicContainer extend={{ container: rules.extendNotFoundContainer }} isFluid>
                    <NotFoundPosition />
                </DynamicContainer>
            )}
        </section>
    );
});
