import type { MessageDescriptor } from 'react-intl';

import { Position, positions } from 'config/positions';
import { routes } from 'config/routes';

import { PositionsSectionType } from './PositionsSection';

const positionIds = Object.values(Position);

const Images = {
    [Position.ANDROID]: 'android.jpg',
    [Position.BACKEND]: 'backend.jpg',
    [Position.DESIGNER]: 'design.jpg',
    [Position.FRONTEND]: 'frontend.jpg',
    [Position.IOS]: 'ios.jpg',
    [Position.PROJECT_MANAGER]: 'pm.jpg',
    [Position.APP_TESTER]: 'tester.jpg',
    [Position.INTERN]: 'trainee.jpg',
    [Position.NOT_FOUND]: '',
} as const;

export interface Column {
    id: Position;
    description: MessageDescriptor['id'];
    title: MessageDescriptor['id'];
    link: string;
    img: (typeof Images)[keyof typeof Images];
}

const getColumns = (positions: Position[]): Column[] =>
    positions.map(id => ({
        id,
        description: `careerPage.positions.${id}.description`,
        title: `careerPage.positions.${id}.title`,
        link: routes.positions[id],
        img: Images[id],
    }));

const activePositions = positionIds.filter(item => positions[item].active && positions[item].displayed);
const inactivePositions = positionIds.filter(item => !positions[item].active && positions[item].displayed);

const activeColumns = getColumns(activePositions);
const inactiveColumns = getColumns(inactivePositions);

const isInternPositionActive = positions[Position.INTERN].active;

const getTitle = (type: PositionsSectionType) => {
    switch (type) {
        case PositionsSectionType.ACTIVE:
            return 'careerPage.positions.active.title';
        case PositionsSectionType.INACTIVE:
            return 'careerPage.positions.inactive.title';
        default:
            return 'careerPage.positions.title';
    }
};

export { activeColumns, inactiveColumns, isInternPositionActive, getTitle };
