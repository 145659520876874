//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import Img from 'gatsby-image';

const Image = ({ styles, src, alt }) => {
    const { formatMessage } = useIntl();
    const imageAlt = formatMessage({ id: alt });

    const images = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "career/positions/detail" } }) {
                nodes {
                    base
                    relativePath
                    childImageSharp {
                        fluid(jpegQuality: 100, maxHeight: 536) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    `);


    const match = React.useMemo(() => images.allFile.nodes.find(({ relativePath }) => `career/positions/detail/${src}` === relativePath), [
        images,
        src,
    ]);

    const {
        childImageSharp: { fluid },
    } = match;

    return (
        <div className={styles.imageContainer}>
            <Img alt={imageAlt} className={styles.image} imgStyle={{ objectPosition: 'left center' }} fluid={fluid} />
            {/* {description && <span className={styles.description}>description</span>} */}
        </div>
    );
};

Image.propTypes = {
    styles: PropTypes.shape().isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default Image;
