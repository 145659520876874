import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import HeroSection from 'modules/hero-section';
import { InquirySection } from 'modules/inquiry';
import { InquiryType } from 'modules/inquiry/InquirySection/content';
import { Offices } from 'modules/offices';
import * as UI from 'modules/ui';

import { GalleriesSection } from '../GalleriesSection';
import { HeroOverlay } from '../HeroOverlay';
import { PositionsSection, PositionsSectionType } from '../PositionsSection';
import { activeColumns, inactiveColumns } from '../PositionsSection/content';
import { SpriteAnimation } from '../SpriteAnimation';
import { TechnologiesSection } from '../TechnologiesSection';
import { TenYearsSection } from '../TenYearsSection';

const { Divider, LazyLoadContainer, SectionNavigation, sectionTitles, TitleTypes, FormattedMessageWithLinks } = UI;

export const CareerPage = forwardRef<HTMLElement>((props, footerRef) => {
    const heroRef = React.useRef(null);
    const positionsRef = React.useRef(null);
    const gallerySectionRef = React.useRef(null);
    const contactRef = React.useRef(null);

    const sections = {
        hero: {
            ref: heroRef,
            title: sectionTitles.hero.general,
            id: 1,
        },
        positions: {
            ref: positionsRef,
            title: sectionTitles.career.positions,
            id: 2,
        },
        gallery: {
            ref: gallerySectionRef,
            title: sectionTitles.career.gallery,
            id: 3,
        },
        offices: {
            ref: contactRef,
            title: sectionTitles.offices.aboutUsPage,
            id: 4,
        },
        footer: {
            ref: footerRef,
            title: sectionTitles.footer,
            id: 5,
        },
    };

    return (
        <>
            <HeroSection
                sectionTitle={<FormattedMessage id="careerPage.hero.sectionTitle" />}
                title={<FormattedMessage id="careerPage.hero.title" />}
                titleAs={TitleTypes.H1}
                description={<FormattedMessageWithLinks id="careerPage.hero.description" />}
                isCareer
                customAnimationObject={
                    <LazyLoadContainer offset={1000} lazyLoaded>
                        <SpriteAnimation />
                    </LazyLoadContainer>
                }
                customContent={<HeroOverlay />}
                ref={heroRef}
            />

            {activeColumns.length > 0 ? (
                <PositionsSection ref={positionsRef} type={PositionsSectionType.ACTIVE} />
            ) : (
                <PositionsSection ref={positionsRef} type={PositionsSectionType.ALL_INACTIVE} />
            )}

            <TechnologiesSection />
            <GalleriesSection ref={gallerySectionRef} />

            {activeColumns.length > 0 && inactiveColumns.length > 0 && (
                <PositionsSection type={PositionsSectionType.INACTIVE} />
            )}

            <TenYearsSection />
            {/* @ts-expect-error */}
            <Divider />
            <Offices ref={contactRef} />
            <InquirySection type={InquiryType.CAREER} />
            {/* @ts-expect-error */}
            <SectionNavigation sections={sections} />
        </>
    );
});
