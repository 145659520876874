import type { TRuleWithTheme } from '@ackee/fela';

export const button: TRuleWithTheme<{ active: boolean }> = ({ active, theme: { colors } }) => ({
    background: active ? colors.fuchsia : 'transparent',

    border: 'none',
    borderBottom: `0.125rem solid ${active ? colors.fuchsia : colors.blue}`,

    color: active ? colors.white : colors.blue,

    fontSize: '1rem',
    fontWeight: active ? 500 : 600,

    marginRight: '2rem',
    marginBottom: '1.875rem',

    paddingLeft: active ? '0.1875rem' : 0,
    paddingRight: active ? '0.1875rem' : 0,

    display: 'inline-flex',
    cursor: 'pointer',

    alignItems: 'center',
    justifyContent: 'center',

    willChange: 'color, background-color, border-color',
    transition:
        'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s, cubic-bezier(0.4, 0.0, 0.2, 1) background-color 0.3s, cubic-bezier(0.4, 0.0, 0.2, 1) border-color 0.3s',

    tabletLarge: {
        marginBottom: 0,
    },
});
